<template>
  <main class="main">
    <div class="hero-section-container">
      <img src="../images/hero-bg.jpg" class="img"/>
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title large">
            Join the Unique Adventure
          </div>
          <div class="title big">
            on roguerust.com Server!
          </div>
          <div class="ip-block">
            <div class="ip-block-wrapper">
              <div class="title">Start Playing Now!</div>
              <div class="title big">{{ $parent.ip }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section welcome-section">
      <div class="wrapper">
        <div class="item">
          <div class="title">
            At our roguerust.com
          </div>
          <div class="desc">
            you'll discover thrilling events, exclusive mods, and a friendly community. Our unique economic system and meticulously crafted gaming environment promise unforgettable adventures!
          </div>
        </div>
        <div class="item">
          <div class="title">
            In our shop
          </div>
          <div class="desc">
            you can use balance to purchase items that will help you stand out and enhance your character. All purchases are instantly delivered directly into the game under your nickname!
          </div>
        </div>
      </div>
    </div>
    <div class="section features-section">
      <div class="wrapper">
        <div class="item">
          <div class="img-container">
            <div class="img-wrapper">
              <img src="../images/map.svg" class="img"/>
            </div>
          </div>
          <div class="text">
            <div class="title">Custom</div>
            <div class="desc">
              maps and missions
            </div>
          </div>
        </div>
        <div class="item">
          <div class="img-container">
            <div class="img-wrapper">
              <img src="../images/prize.svg" class="img"/>
            </div>
          </div>
          <div class="text">
            <div class="title">Special</div>
            <div class="desc">
              events and tournaments
            </div>
          </div>
        </div>
        <div class="item">
          <div class="img-container">
            <div class="img-wrapper">
              <img src="../images/reward.svg" class="img"/>
            </div>
          </div>
          <div class="text">
            <div class="title">Unique</div>
            <div class="desc">
              progression and reward systems
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section steps-section" id="how">
      <div class="wrapper">
        <img src="../images/stepsBg.jpg" class="bg"/>
        <div class="steps-block">
          <div class="title title-main">
            Steps on how to start playing on the server:
          </div>
          <div class="item">
            <div class="img-container">
              <div class="img-wrapper">
                <div class="title">1.</div>
              </div>
            </div>
            <div class="text">
              <div class="desc">
                Installing a legal copy of Rust is a must for accessing our gaming servER
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img-container">
              <div class="img-wrapper">
                <div class="title">2.</div>
              </div>
            </div>
            <div class="text">
              <div class="desc">
                Сopy the ip: {{ $parent.ip }}
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img-container">
              <div class="img-wrapper">
                <div class="title">3.</div>
              </div>
            </div>
            <div class="text">
              <div class="desc">
                And it's all! you can start playing, and if you want to purchase in-game items, you can visit our store
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section general-info-section">
      <div class="wrapper">
        <div class="title big">General Information</div>
        <div class="list">
          <div class="item">
            <div class="title">Server Location</div>
            <div class="desc">
              Hosted on high-performance servers to ensure minimal latency.
            </div>
          </div>
          <div class="item">
            <div class="title">Community Events</div>
            <div class="desc">
              Weekly and monthly competitions with great prizes.
            </div>
          </div>
          <div class="item">
            <div class="title">Support</div>
            <div class="desc">
              Dedicated support team to assist you with any issues.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section rules-section">
      <div class="wrapper">
        <div class="title big">Brief Rules:</div>
        <div class="list">
          <div class="item">
            <div class="img-container">
              <div class="img-wrapper">
              </div>
            </div>
            <div class="text">
              <div class="desc">
                Respect all players and staff.
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img-container">
              <div class="img-wrapper">
              </div>
            </div>
            <div class="text">
              <div class="desc">
                No cheating or exploiting game bugs.
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img-container">
              <div class="img-wrapper">
              </div>
            </div>
            <div class="text">
              <div class="desc">
                Follow all community guidelines to ensure a fun and fair environment.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Home',
  props: ['currency', 'currencyCode'],
  components: {
  },
  data: function() {
    return {
    }
  },
  mounted() {
    
  },
  methods: {
    
  }
}
</script>