<template>
  <div id="app" class="app">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <transition name="fade">
          <Menu @setAmount="setAmount" v-if="menuVisible" @scrollToSection="scrollToSection" :currency="currency" :currencyValue="currencyValue" :currencyCode="currencyCode" @changeCurrency="changeCurrency"/>
        </transition>
        <div class="mob menu-icon" @click="toggleMenu" :class="{'active': menuVisible}">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <Header @setAmount="setAmount" @scrollToSection="scrollToSection" :currency="currency" :currencyValue="currencyValue" :currencyCode="currencyCode" @changeCurrency="changeCurrency"/>
        <router-view @setAmount="setAmount" @topUp="topUp" @formatDate="formatDate" :currency="currency" :currencyCode="currencyCode" @openProductModal="openProductModal"/>
        <Footer @goToPage="goToPage" />
        <transition name="fade">
          <ProductModal v-if="productModalIsVisible" @closeProductModal="closeProductModal" :currency="currency" />
        </transition>
        <transition name="fade">
          <Login
              v-if="signInModalIsVisible"
              @closeSignInModal="closeSignInModal"
              @login2="login2"
          />
        </transition>
        <transition name="fade">
          <TopUpModal
              v-if="topUpModalIsVisible"
              :currency="currency"
              @setTopUpAmount="setTopUpAmount"
          />
        </transition>
        <transition name="fade">
          <OrderModal
              v-if="orderModalIsVisible"
              :currency="currency"
              @topUp="topUp"
          />
        </transition>
        <transition name="fade">
          <AgeModal v-if="ageModalIsVisible"/>
        </transition>
        <div 
          v-if="cookesModalIsVisible"
          class="modal-overlay" 
        >
          <div class="cookies-modal">
            <div class="title">
              Cookies Unwrapped
            </div>
            <div class="desc">
              We use cookies to enhance your experience on our website. Essential cookies are necessary for basic operations and are set by default. We also employ additional cookies for various purposes that enhance your experience but are not required for core functions. You can adjust your cookie settings at any time. For more details, please refer to our Cookie Notice.
              <a 
                class="desc"
                @click="goToPage('cookie')" 
              >
              Cookie Notice
              </a>.
            </div>
            <button 
              class="button" 
              @click="acceptCookies"
            >
            Ok, got it!
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Login from './components/Login.vue'

import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'
import ProductModal from './components/ProductModal.vue'
import {scroller} from 'vue-scrollto/src/scrollTo'
import {loadScriptsFromString} from "@/utils/load_scripts";
import TopUpModal from './components/TopUpModal.vue'
import OrderModal from './components/OrderModal.vue'
import AgeModal from './components/AgeModal.vue'

export default {
  name: 'app',
  components: {
    Header,
    Menu,
    Footer,
    ProductModal,
    Login,
    TopUpModal,
    OrderModal,
    AgeModal
  },
  data: function() {
    return {
      ageModalIsVisible: true,
     pageLoaded: false,
     menuVisible: false,
      isAuth: false,
      error: '',
      successMessage: '',
      orderHistory: [],
      depositHistory: [],
      textPageTitle: '',
      textPageHeader: '',
      textPageContent: '',
      imgDomain: '',
      textPageList: '',
      currency: '',
      currencyValue: '',
      currencyCode: '',
      currencyOptions: [],
      hostname: '',
      productModalIsVisible: false,
      chosenProoduct: {},
      profileData: {},
      countryOptions: [],
      amount: null,
      socials: [],
      analyticsInitialized: false,
      settings: {},
      signInModalIsVisible: false,
      topUpModalIsVisible: false,
      orderModalIsVisible: false,
      topUpAmount: '',
      ip: '91.108.122.86:28015',
      cookesModalIsVisible: false
    }
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.closeMenu();
      if (this.isAuth) {
        this.getProfileData();
      }
    }
  },
  methods: {
    closeAgeModal() {
      this.ageModalIsVisible = false;
    },
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted');

      if (!cookiesAccepted) {
          this.cookesModalIsVisible = true;
      }
    },
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', 'true');
      this.cookesModalIsVisible = false;
    },
    setTopUpAmount(amount) {
      this.topUpAmount = amount;
      this.openOrderModal();
    },
    closeOrderModal() {
      this.orderModalIsVisible = false;
    },
    openOrderModal() {
      this.topUpModalIsVisible = false;
      this.orderModalIsVisible = true;
    },
    closeTopUpModal() {
      this.topUpModalIsVisible = false;
    },
    openTopUpModal() {
      this.orderModalIsVisible = false;
      this.topUpModalIsVisible = true;
    },
    login2(data) {
      this.error = '';
      this.$http
          .post(process.env.VUE_APP_API + "login", data)
          .then((res) => {
            if (res.data.status == "OK") {
              this.isAuth = true;
            } 
          })
          .catch(() => {
            
          });
    },
    closeSignInModal() {
      this.signInModalIsVisible = false;
    },
    openSignInModal2() {
      this.signInModalIsVisible = true;
    },
    setAmount(am) {
      this.amount = am;
    },
    closeProductModal() {
      this.productModalIsVisible = false;
    },
    openProductModal(product) {
      this.productModalIsVisible = true;
      this.chosenProoduct = product;
    },
    scrollToSection(section) {
      if (this.$route.name != 'Home') {
        this.$router.push({path: '/'});
      }
      setTimeout(()=> {
        const firstScrollTo = scroller()
        firstScrollTo(section)
      }, 200)
    },
    getDomainName(hostName) {
      return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "auto";
      }
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    openSignInModal() {
      window.location.replace("https://api.roguerust.com/auth/steam/redirect");
    },
    logout() {
     this.$http.get(process.env.VUE_APP_API + 'user/logout')
      .then(() => {
        this.isAuth = true;
        this.$router.push({path: '/'});
        this.isAuth = false;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getDepositHistory() {
      this.$http.get(process.env.VUE_APP_API + 'payments/history')
      .then((res) => {
        this.depositHistory = res.data.data
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getOrderHistory() {
      this.$http.get(process.env.VUE_APP_API + 'orders/history')
      .then((res) => {
        this.orderHistory = res.data.data
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
          day = '0' + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
          month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, '0');
      }
      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ':' + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + ' ' + hoursAndMinutes;

      return format
    },
    openProduct(item) {
      this.$router.push({path: '/products/' + item.id, params: {data: item.id}});
      this.getProductPageContent(item.id);
    },
    goToPage(item) {
      let newItem;
      if (item == 'privacy') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('privacy')) {
            newItem = this.textPageList[i].id;
          } 
        }
      } else if (item == 'terms') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('terms')) {
            newItem = this.textPageList[i].id;
          } 
        }
      } else if (item == 'cookie') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('cookie')) {
            newItem = this.textPageList[i].id;
          } 
        }
      } else {
        newItem = item
      }
      this.$router.push({path: '/pages/' + newItem, params: {data: newItem}})
      this.getTextPageContent(newItem);
    },
    getTextPageContent(id) {
      this.textPageTitle = '';
      this.textPageHeader = '';
      this.textPageContent = '';
      this.$http.get(process.env.VUE_APP_API + 'static-pages/' + id)
      .then((res) => {
        this.textPageTitle = res.data.payload.title;
        this.textPageContent = res.data.payload.content;
        this.textPageHeader = res.data.payload.header;
      })
      .catch(() => {
        
      })
    },
    getSettings() {
      return this.$http.get(process.env.VUE_APP_API + 'settings')
          .then(async (res) => {
            this.settings = res.data.payload;
            const snippet = res.data.payload.google_tag;
            if (!this.analyticsInitialized && snippet) {
              await loadScriptsFromString(snippet);
              this.analyticsInitialized = true;
            }
          })
          .catch(() => {})
    },
    getTextPages() {
      
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
      .then((res) => {
        this.textPageList = res.data.payload
      })
      .catch((res) => {
        console.log(res)
      })
    },
    getSocials() {
      this.$http.get(process.env.VUE_APP_API + 'social-links')
      .then((res) => {
        this.socials = res.data.payload
      })
      .catch((res) => {
        console.log(res)
      })
    },
    topUp(data) {
      this.$http.post(process.env.VUE_APP_API + 'payments/checkout', data)
      .then((res) => {
        if (res.data.status == "OK") {
          window.location = res.data.redirect_url;
        } else {
          this.error = res.response.data.message
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        } else {
          this.error = res.response.data.message;
        }
      })
    },
    getProfileData() {
      let self = this;
      self.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          self.getOrderHistory();
          self.getDepositHistory();
          self.profileData = res.data.payload;
      })
      .catch(() => {
        
      })
    },
    getCurrencies() {
      return this.$http.get(process.env.VUE_APP_API + 'currencies')
      .then((res) => {
          const that = this;
          this.currencyOptions = res.data.payload;

          if (localStorage.getItem("currency")) {
            this.currencyCode = localStorage.getItem("currency");

            const currency = res.data.payload.find(({code}) => code === that.currencyCode);

            if (currency) {
              this.currencyValue = currency.value;
              this.currency = currency.symbol;
            }
          } else {
            this.currencyCode = this.currencyOptions[0].code;
            this.currency = this.currencyOptions[0].symbol;
            this.currencyValue = this.currencyOptions[0].value;
          }
      })
      .catch(() => {
       
      })
    },
    changeCurrency(item) {
      this.currencyCode = item;
      this.checkCurrency();
    },
    checkCurrency() {
      const that = this;
      const currency = this.currencyOptions.find(({code}) => code === that.currencyCode);

      if (currency) {
       
        this.currency = currency.symbol;
        this.currencyValue = currency.value;
        console.log(currency.symbol)

        if (this.isAuth) {
          this.getProfileData();
        }
      }
    },
  },
  mounted: function() {
    if (localStorage.getItem('age')) {
      this.ageModalIsVisible = false
    }
    this.cookiesModal();
    this.hostname = this.getDomainName(window.location.hostname)
    this.currency = process.env.VUE_APP_CURRENCY;
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.pageLoaded = true;
    let self = this;
    window.addEventListener("resize", self.menuHeight);
    self.getSettings();
    self.menuHeight();
    self.getTextPages();
    self.getSocials();
    self.getCurrencies();
    
    self.$http.get(process.env.VUE_APP_API + 'user/is-auth')
    .then((res) => {
        console.log('auth: ' + res.data.authenticated);
        self.isAuth = res.data.authenticated

        if (res.data.authenticated) {
          self.getProfileData();
        }
    })
    .catch(() => {
       
    })
    
  },
  computed: {
    minOrderAmount: function () {
      return this.settings && this.settings.min_order_amount;
    }
  }
}
</script>
