<template>
	<footer class="footer" id="footer">
    <div class="section join-section" v-if="!$parent.isAuth">
      <div class="wrapper">
        <div class="title big">Join us today</div>
        <div class="title">and begin your unique adventure in the world of Rust!</div>
        <div class="buttons">
          <button v-clipboard="$parent.ip" v-clipboard:success="clipboardSuccessHandler" class="button">
            {{copied ? 'Copied' : 'Join' }}
          </button>
          <router-link to="/contact-us" class="button black">
            Support
          </router-link>
        </div>
      </div>
    </div>
    <div class="section nav-section">
      <div class="wrapper">
        <ul class="nav">
          <li class="nav__item">
            <router-link to="/products" class="title small">
              ITEMS
            </router-link>
          </li>
          <li class="nav__item">
            <router-link to="/" class="title small">
              HOME
            </router-link>
          </li>
          <li class="nav__item" v-for=" (item, i) in $parent.textPageList" :key="i">
            <a @click="$emit('goToPage', item.id)" class="title small" >
              {{ item.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="section footer-section">
      <div class="wrapper">
        <div class="left">
          <div class="desc">
            {{ $parent.settings.copyright }}
            <br/>
            © {{ new Date().getFullYear() }}
          </div>
        </div>
        <div class="right">
          <div class="desc">
            {{ $parent.settings.requisites }}
          </div>
          <div class="desc">
            Contact: <a :href="'mailto:' + $parent.settings.support_email">{{ $parent.settings.support_email }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="section methods-section">
      <div class="wrapper">
        <div class="left">
          <img src="../images/visa.svg" class="img"/>
          <img src="../images/mc_symbol.svg" class="img"/>
          <img src="../images/3ds.svg" class="img"/>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Vue from 'vue'
import Clipboard from 'v-clipboard'
Vue.use(Clipboard)


export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
      copied: false
		}
	},
	methods: {
    clipboardSuccessHandler() {
      let self = this;
      self.copied = true;
      setTimeout(() => {
        self.copied = false;
      }, 1200);
    }
		
	},
	mounted() {
    
  }
}
</script>
